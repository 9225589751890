
/**
 * 获取USER_ID
 * @param {USER_ID} name 
 */
export const getQueryString = name => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');

  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }

  return null;

}




export const load_images = arts_url_arr=> {
  return Promise.all(arts_url_arr.map(src => loadImg(src)))
      .then(() => {
          return true;
      }).catch(error => {
          console.log(error);
      })
}


/**
 * 图片加载程序
 */
function loadImg(src){
    return new Promise((resolve, reject) => {

        // 每次show_image_info要清零
        let show_image_info = [];

        const img = new Image();


        img.src = src;

        // getImageData 需要设置跨域
        img.crossOrigin = "anonymous";

        img.onload = function () {

            const {
                width,
                height
            } = img;

            let info = {
                height,
                width,
                src
            };

            show_image_info.push(info);


            resolve(img)

            // 取得主色,以及图片的原始尺寸

        }
        img.onerror = function (e) {
            reject(e);
        }
    })
}
