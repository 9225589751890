import React from "react";

import{IonHeader,IonToolbar,IonTitle} from '@ionic/react';


export default function homeHeader() {
  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonTitle>芽希启萌</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}
