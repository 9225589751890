import React, { PureComponent } from "react";
import { Route } from "react-router-dom";
import { getQueryString } from "./utils";
import Home from "./pages/home/main";

import store from  './store';
import{getUserId} from './store/actionCreators';


import {IonRouterOutlet, IonApp } from "@ionic/react";

export default class App extends PureComponent {

  componentDidMount(){
    //从URL中取得传递过来的 userID
    const wechatId = getQueryString('userId');

    if(wechatId){
      store.dispatch(getUserId(wechatId))
    }else{
      store.dispatch(getUserId('null'))
    }
  }

  render() {


    return (
      <IonApp>
        <IonRouterOutlet id="main-content">
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
        </IonRouterOutlet>
      </IonApp>
    );
  }
}