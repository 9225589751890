import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";


/* Theme variables */
import './theme/variables.css';

import { IonReactRouter } from "@ionic/react-router";

// 是否为微信测试
// function isWeiXin(){

//   var ua = window.navigator.userAgent.toLowerCase();

//   if(ua.match(/MicroMessenger/i) == 'micromessenger'){

//       return true;

//   }else{

//       return false;

//   }

// }

// if(isWeiXin()){

// alert(1);

// }else{

// alert(2);

// }



ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <IonReactRouter>
        <App />
      </IonReactRouter>
    </React.StrictMode>
  </Provider>,

  document.getElementById("root")
);

