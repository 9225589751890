import {
  createStore,
  applyMiddleware,
  compose
} from 'redux'; // 加入compose


import thunkMiddleware from 'redux-thunk'; // 加入redux-thunk
import reducer from './reducer.js';

// composeEnhancers函数 -用于浏览器Redux DEVtools

// __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace:true})  其中的({trace:true})为可选

let store = {};

if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__){
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true
  }) || compose;

  // 应用中间件
  const storeEnhancer = applyMiddleware(thunkMiddleware);
  store = createStore(reducer, composeEnhancers(storeEnhancer)); // 使用第二个参数storeenhancer

}else {

  const storeEnhancer = applyMiddleware(thunkMiddleware);
  store = createStore(reducer, storeEnhancer); // 第二个参数使用storeEnhancer
}

export default store;


