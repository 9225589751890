import {
  IonContent,
  IonButton,
  IonPage,
  IonAlert,
  IonToast,
  IonLoading,
} from "@ionic/react";


import React, { PureComponent, createRef } from "react";
import InputGroup from "../../../components/input_group";
import HomeHeader from "../../../components/homeHeader";
import ThemeSelect from "./ThemeSelect";
import store from "../../../store";
import axios from "axios";
import { load_images } from "../../../utils";
import { StyleWrapper } from "./style.js";

import { baseURL } from "../../../config";

export default class name extends PureComponent {
  state = {
    name: "",
    mail: "",
    code: "",
    themeId: 0,
    wechatId: store.getState().wechatId,
    image_load: false,
    showAlert: false,
    showToast: false,
    showLoading: false,
    alert_header: "提示信息",
    alert_message: "",
    alert_buttons: ["确认"],
    toast_message: "",
    loading_message: "",
  };

  name_ref = createRef();
  mail_ref = createRef();
  code_ref = createRef();

  componentDidMount() {

    // theme的图片
    const imgs = [
      baseURL + "./image/a2.jpg",
      baseURL + "./image/b2.jpg",
      baseURL + "./image/c2.jpg",
      baseURL + "./image/d2.jpg",
    ];

    // 批量加载图片
    load_images(imgs).then((result) => {
      this.setState({
        image_load: true,
      });
    });
  }
//            duration={5000}
  render() {
    return (
      <StyleWrapper>
        <IonPage>
          <HomeHeader />
          <IonContent class="ion-padding-horizontal">
            <IonAlert
              isOpen={this.state.showAlert}
              onDidDismiss={() => this.setState({ showAlert: false })}
              header={this.state.alert_header}
              message={this.state.alert_message}
              buttons={this.state.alert_buttons}
            />

            <IonToast
              isOpen={this.state.showToast}
              onDidDismiss={() => this.setState({ showToast: false })}
              message={this.state.toast_message}
              position="middle"
              duration={3500}
            />

            <IonLoading
              isOpen={this.state.showLoading}
              onDidDismiss={() => this.setState({ showLoading: false })}
              message={this.state.loading_message}
   
            />

            <InputGroup
              ref={this.name_ref}
              label="宝宝姓名："
              require={true}
              getValue={this.getName}
              size={"2,4"}
              checkCallback={this.checkName}
            />

            <InputGroup
              ref={this.mail_ref}
              label="接收邮箱："
              require={true}
              type="mail"
              getValue={this.getEmail}
            />

            <h6 id="test">选择字帖样式</h6>

            {this.state.image_load && (
              <ThemeSelect
                themeId={this.state.themeId}
                setTheme={this.getThemeId}
              />
            )}

            <InputGroup
              ref={this.code_ref}
              label="生成码："
              require={true}
              getValue={this.getCode}
            />

            <IonButton
              color="secondary"
              className="ion-margin-top"
              expand="block"
              onClick={this.sumbitInfo}
            >
              <strong>制作名帖</strong>
            </IonButton>
          </IonContent>
        </IonPage>
      </StyleWrapper>
    );
  }

  // 自定义验证函数
  checkName = (that, value) => {
    if (!/^[\u3a00-\ufa99]+$/.test(value)) {
      that.setState({
        hasWrong: true,
        errorInfo: "请输入中文",
      });
      return;
    }
  };

  getName = (name) => this.setState({ name });
  getEmail = (mail) => this.setState({ mail });
  getCode = (code) => this.setState({ code });
  getThemeId = (themeId) => this.setState({ themeId });

  // 提交信息
  sumbitInfo = () => {
    // 从state中获取填写的信息
    const { name, mail, code, themeId,wechatId } = this.state;

    let data = {
     username: name,
      mail: mail,
      code: code,
      theme: themeId,
      wechatId:wechatId
    }


    // 如果没有输入信息
    if (name === "" || mail === "" || code === "") {
      this.setState({
        showAlert: true,
        alert_header: "提示信息",
        alert_message: "请根据提示正确输入信息。",
        alert_buttons: [
          {
            text: "确定"
          },
        ],
      });
      name === "" && this.name_ref.current.submitValue();
      mail === "" && this.mail_ref.current.submitValue();
      code === "" && this.code_ref.current.submitValue();
      return;

    } else { // 按要求正确输入信息
      this.setState({
        showAlert: true,
        alert_header: "确认信息",
        alert_message: `
        宝宝姓名：${name}<br/>
        接收邮箱：${mail}<br/>
        字帖主题：${[ "小鹿丘比", "蓝鲸鲁尼", "绿萝莎莎","熊猫希萌"][themeId]}
        <hr/>
        生成编码：<strong>${code.toUpperCase()}</strong><br/>
        `,
        alert_buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "确定",
            handler:() =>{
              this.sendInfo(data);
            },
          },
        ],
      });
    }



    console.log(this.state);

    // this.name_ref.aaa();
    // this.name_ref.current.submitValue();
    // this.mail_ref.current.submitValue();
    // this.code_ref.current.submitValue();
  };

  sendInfo = (data) => {

    // 设置显示 loading
    this.setState({
      showLoading: true,
      loading_message: "正在提交信息...",
    });

    axios({
      url: "http://yaxi.siker.cn/name_zitie",
      data,
      method: "post",
    })
      .then((res) => {
        setTimeout(() => {
          console.log (res.data);

          // 成功提交后清空生成码输入框，防止多次提交。
          if(res.data.status===true) {
            this.code_ref.current.setEmpty();

            this.setState({
              code:"",
              showLoading: false,
              showToast: true,
              toast_message: res.data.message,
            });
          }else{ //出现错误

            this.setState({
              showLoading: false,
              showAlert: true,
              alert_header: "错误提示",
              alert_message: res.data.message,
              alert_buttons: [
                {
                  text: "确定"
                },
              ],
            });
          }

        }, 1000);
      })
      .catch((err) => {
        this.setState({
          showLoading: false,
          showToast: true,
          toast_message: "连接服务器出错",
          
        });
      });
  };
}
