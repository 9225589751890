import * as TYPES from './constants.js';

// import * as actionTypes from './constants';

const defaultState = {
  wechatId: '',
  themeId: 0,
  primaryColor:'#7469E3',
  secondaryColor:'#46D5BD',
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case TYPES.GET_USER_ID:
      // let userInfo = {
      //   ...state.userInfo,
      //   wechatId: action.user_id
      // };
      return {
        ...state,
        wechatId:action.wechatId
      };
    case TYPES.SAVE_THEME_ID :
      return {
        ...state,
        themeId: action.themeId
      };
    default:
      return state;
  }
}

export default reducer;