import React, { memo } from "react";
import styled from "styled-components";

import { baseURL } from "../../../config";

import { checkmarkCircle } from "ionicons/icons";

import { IonIcon, IonSlide, IonSlides } from "@ionic/react";

import store from "../../../store";

const { primaryColor } = store.getState();


const StyleWrapper = styled.div`
  ion-slides {
    padding: 0.5rem;

    ion-slide {
      color: #555;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      border-radius: 0.5rem;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.12);

      .themeInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 1rem;
        margin-top: 0.2rem;

        ion-icon {
          width: 1.2rem;
          height: 1.2rem;
          visibility: hidden;
        }
      }

      &.active {
        color: ${primaryColor};

        .themeInfo{
          font-weight:800;
        }

        ion-icon {
          visibility: visible;
        }
      }
    }

    ion-slide img {
      display: block;
    }
  }
`;

export default memo(function ThemeSelect(props) {
  const themes = [

    {
      img: "./image/a2.jpg",
      title: "小鹿丘比",
    },
    {
      img: "./image/b2.jpg",
      title: "蓝鲸鲁尼",
    },
    {
      img: "./image/c2.jpg",
      title: "绿萝莎莎",
    },
    {
      img: "./image/d2.jpg",
      title: "熊猫希萌",
    },
  ];

  const slideOpts = {
    slidesPerView: 2.5,
    spaceBetween: 10,
  };

  const themeId = props.themeId;

  return (
    <StyleWrapper>
      <IonSlides id="abc" options={slideOpts}>
        {themes.map((item, index) => {
          return (
            <IonSlide
              className={themeId === index && "active"}
              key={item.title}
              onClick={(e) => props.setTheme(index)}
            >
              <img src={baseURL + item.img} alt="" />
              <div className="themeInfo">
                {item.title}
                <IonIcon icon={checkmarkCircle} />
              </div>
            </IonSlide>
          );
        })}
      </IonSlides>
    </StyleWrapper>
  );
});
