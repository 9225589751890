import React, { PureComponent } from "react";
import { IonItem, IonLabel, IonInput } from "@ionic/react";

class input_group extends PureComponent {
  state = {
    // 用于临时保存值
    theValue: "",
    hasWrong: false,
    errorInfo: "",
  };




  // 传递输入值并校验
  submitValue = () => {
    const inputValue = this.state.theValue;

    //必填检测
    if (this.props.require){
      console.log (this.props.require);

      if(inputValue.length===0){
          this.setState({
            hasWrong: true,
            errorInfo: "必填项",
          });
          return;
      }
    }

    //类型检测
    if (this.props.type) {
      // 如果类型是 mail
      switch (this.props.type) {
        case "mail":
          // 如果不是合法邮箱地址则报错
          if (
            !inputValue.match(
              /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
            )
          ){
            this.setState({
              hasWrong: true,
              errorInfo: "需填写可用邮箱地址",
            });
            
          }
          break;
        default:
          break;
      }
    }

    // 长度检测

    if (this.props.size) {


      const [minSize, maxSize] = this.props.size.split(',');

      if (inputValue.length < minSize || inputValue.length > maxSize) {
        // 生成错误提示语句
        let errorInfo = "";
        if (minSize <= 0) {
          errorInfo = `最多${maxSize}字`;
        } else {
          errorInfo = `最少${minSize}字，最多${maxSize}字`;
        }
        this.setState({
          hasWrong: true,
          errorInfo: errorInfo,
        });
        return;
      }
    }

    // 自定义检测函数
    if (this.props.checkCallback) {
      this.props.checkCallback(this, inputValue);
    }

    if (this.state.hasWrong === false) {
      // 通过以上测试，返回数据
      this.props.getValue && this.props.getValue(inputValue);
    }
  };

  setErrorInfo(errorInfo){
    this.setState({
      hasWrong: true,
      errorInfo: errorInfo,
    });
  }

  // 激活控件 - 检测是否有错误信息
  reset = (e) => {
    // 如果有错误信息，则提示
    if (this.state.hasWrong) {
      this.setState({
        hasWrong: false,
        errorInfo: "",
      });
      e.target.value = "";
    }

    console.log ( e.target.getBoundingClientRect());
    setTimeout(()=>{
      e.target.scrollIntoViewIfNeeded(true);
    },500)
  };

  getValue = (value) => {
    this.setState({
      theValue: value,
    });
  };

  setEmpty = () => {
    this.setState({
      theValue: '',
    });
  };

  render() {

    return (
      <IonItem>
        <IonLabel position="floating">
          <span>{this.props.label}</span>
          {this.state.hasWrong && (
            <span style={{ color: "red", fontSize: "1rem" }}>
              {this.state.errorInfo}
            </span>
          )}
        </IonLabel>
        <IonInput
          type={this.props.type ? this.props.type : "text"}
          onIonChange={
            this.props.getValue && ((e) => this.getValue(e.detail.value))
          }
          onIonFocus={(e) => this.reset(e)}
          onIonBlur={() => this.submitValue()}
          value ={this.state.theValue}
        ></IonInput>
      </IonItem>
    );
  }
}

export default input_group;
