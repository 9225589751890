import React, { PureComponent } from "react";

import { Route, Redirect } from "react-router-dom";

import {
  IonRouterOutlet,
} from "@ionic/react";

import Name from "./name";



// import HomeHeader from "../../components/homeHeader";
// import TabBar from "./TabBar";
// import { IonContent, IonPage } from "@ionic/react";

class main extends PureComponent {
  constructor() {
    super();

    this.state = {
      tabshow: true,
    };
    window.onresize = () => {
      this.setState({
        tabshow: !this.state.tabshow,
      });
    };
  }
  render() {
    return (
      <>
          <IonRouterOutlet>
            <Route exact path="/" component={Name} />
            <Route path="/home/name" component={Name} />
            <Redirect exact path="/" to="/home/name" />
          </IonRouterOutlet>
      </>
    );
  }
}

export default main;
