// import axios from 'axios';

import * as TYPES from './constants.js';



// 从URL中获取用户ID
export const getUserId = wechatId => ({
  type: TYPES.GET_USER_ID,
  wechatId
});

// 将用户ID保存到服务器
export const save_user_id = user_id => ({
  type: TYPES.SAVE_USER_ID,
  user_id
});

// 发送用户输入的KEY到服务器上验证
export const check_key = key => ({
  type: TYPES.CHECK_KEY,
  key
});

// 将用户填写的信息发送到服务器，生成字帖
export const g_name_zitie = zite_info => ({
  type: TYPES.G_NAME_ZITIE,
  zite_info
});


// 保存选中的 theme id
export const save_theme_id = value => ({
  type: TYPES.SAVE_THEME_ID,
  themeId:value
});








// // redux-thunk中定义的action函数
//  // 二个参数，一个dispatch用于发射,一个getState可以获取state中数据 
// export const getHomeMultidataAction = (dispatch,getState)=>{

//   // 这个dispatch本质上就是store中的dispatch
//   console.log ("action函数中",dispatch);
  
//   axios({
//       url:"http://123.207.32.32:8000/home/multidata",
//   }).then(res=>{
//       const data = res.data.data;
//       dispatch(changeBannersAction(data.banner.list));
//       dispatch(changeRecommendAction(data.recommend.list));
//   })
  
// }